import mock from 'src/utils/mock';

mock
  .onGet('/api/clients/sources')
  .reply(() => {
    const sources = {
      "algolead": {
        id: "algolead",
        name: "AlgoLead",
        accounts: {
          "Josh": {
            id: "Josh",
            name: "Josh",
          },
        },
      },
      "roicollective": {
        id: "roicollective",
        name: "ROICollective",
        accounts: {
          "Josh": {
            id: "Josh",
            name: "Josh",
          },
        },
      },
      "meekseeksmedia": {
        id: "meekseeksmedia",
        name: "MeekseeksMedia",
        accounts: {
          "Josh": {
            id: "Josh",
            name: "Josh",
          },
        },
      },
    };
    return [200, {
      sources
    }];
  });

mock
  .onGet('/api/clients/columns/algolead')
  .reply(() => {
    const columns = [{
      field: "UserID",
      title: "User ID"
    }, {
      field: "AccountID",
      title: "Account ID"
    }, {
      field: "Country",
      title: "Country"
    }, {
      field: "SaleStatus",
      title: "Sale Status"
    }, {
      field: "Balance",
      title: "Balance",
      align: "right",
      type: "numeric"
    }, {
      field: "CreateTime",
      title: "Create Time",
      type: "date"
    }
    ];

    return [200, {
      columns
    }];
  });


mock
  .onGet('/api/clients/columns/roicollective')
  .reply(() => {
    const columns = [{
      field: "campaignName",
      title: "Campaign Name"
    }, {
      field: "customerID",
      title: "Customer ID",
    }, {
      field: "countryName",
      title: "Country Name"
    }, {
      field: "countryCode",
      title: "Country Code",
    }, {
      field: "custom1",
      title: "Custom 1"
    }, {
      field: "custom2",
      title: "Custom 2"
    }, {
      field: "saleStatus",
      title: "Sale Status"
    },
    {
      field: "signupDate",
      title: "Signup Date",
    }
    ];

    return [200, {
      columns
    }];
  });

  mock
  .onGet('/api/clients/columns/meekseeksmedia')
  .reply(() => {
    const columns = [{
      field: "campaignName",
      title: "Campaign Name"
    }, {
      field: "customerID",
      title: "Customer ID",
    }, {
      field: "countryName",
      title: "Country Name"
    }, {
      field: "countryCode",
      title: "Country Code",
    }, {
      field: "custom1",
      title: "Custom 1"
    }, {
      field: "custom2",
      title: "Custom 2"
    }, {
      field: "brokerID",
      title: "Broker ID"
    }, {
      field: "saleStatus",
      title: "Sale Status"
    },
    {
      field: "signupDate",
      title: "Signup Date",
    }
    ];

    return [200, {
      columns
    }];
  });
